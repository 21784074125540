import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withStyles, { ThemeProvider } from 'react-jss'
import { observer } from 'mobx-react'
import { toJS } from 'mobx'

import { getThemeProps } from '@elo-kit/utils/block.utils'
import { CUSTOM_CLASS_NAME_OPTION } from '@elo-kit/constants/customCss.constants'
import { NumberField } from '@elo-kit/components/form/number-field/NumberField'
import { PdfReaderComponent } from '@elo-kit/components/pdf-reader'
import { isPDFBlockAllowed } from 'utils/browsers.utils'
import { GET_API_URL } from 'utils/requests.utils'
import { patchLink } from 'utils/link.utils'

import filePdfStyles from './FilePdf.styles'

class FilePdfContainer extends Component {
  render() {
    const { block = {}, previewMode, previewType, pageBuilderPreview, classes, theme } = this.props

    const { contentPageId, id, goods = [], content } = block

    const good = goods[0] || {}
    const { digital: { name, file = {} } = {} } = good

    const fileUrl = contentPageId
      ? GET_API_URL(`/cabinet/content_blocks/${id}/download_good`, {
          contentPageId,
          goodId: good.id,
        })
      : file.icon

    if (previewMode && !good.id) {
      return (
        <div className={classNames('no-pdf', classes.noPdfContainer)}>
          <i className='fas fa-file-pdf' />
        </div>
      )
    }

    if (!good.id) {
      return null
    }

    if (!isPDFBlockAllowed) {
      return null
    }

    return (
      <PdfReaderComponent
        id={String(id)}
        customClassName={content[CUSTOM_CLASS_NAME_OPTION]}
        classes={classes}
        previewMode={previewMode}
        previewType={previewType}
        pageBuilderPreview={pageBuilderPreview}
        fileName={name || file.name}
        fileUrl={patchLink(fileUrl)}
        NumberFieldComponent={NumberField}
        theme={theme}
        block={toJS(block)}
      />
    )
  }
}

FilePdfContainer.propTypes = {
  block: PropTypes.object,
  previewType: PropTypes.string,
  pageBuilderPreview: PropTypes.bool,
  previewMode: PropTypes.bool,
  locales: PropTypes.shape({
    docIsNotVisibleTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    docIsNotVisibleContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    docIsNotVisibleBtnContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
    renderPaginationInfo: PropTypes.func,
  }),
}

FilePdfContainer.defaultProps = {
  previewMode: false,
  pageBuilderPreview: false,
  block: {
    content: {},
  },
}

export const FilePdfWithStyles = withStyles(filePdfStyles)(FilePdfContainer)

/**
 * File Pdf - PDF reader block
 */
@observer
class FilePdf extends Component {
  render() {
    const { block, previewMode, previewType, pageBuilderPreview } = this.props

    return (
      <ThemeProvider theme={getThemeProps(block)}>
        <FilePdfWithStyles
          block={toJS(block)}
          previewMode={previewMode}
          previewType={previewType}
          pageBuilderPreview={pageBuilderPreview}
        />
      </ThemeProvider>
    )
  }
}

FilePdf.defaultProps = {
  previewMode: false,
  pageBuilderPreview: false,
  previewType: '',
  block: {
    content: {},
  },
}

export { FilePdf }
export default FilePdf
