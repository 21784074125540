import React from 'react'

import { patchLink } from 'utils/link.utils'

const NotificationSection = ({
  classes,
  icon,
  imgUrl,
  alt,
  title,
  description,
  onClick,
  btnText,
  btnIcon,
  message,
  link,
  linkName,
}) => (
  <div className={classes.notificationContainer}>
    {icon && <i className={icon} />}
    {imgUrl && <img src={imgUrl} alt={alt} />}
    {title && <h1>{title}</h1>}
    {description && <p>{description}</p>}
    {message && <div className={classes.messageBox}>{message}</div>}
    {link && (
      <a className={classes.link} href={patchLink(link)}>
        {linkName}
      </a>
    )}
    {onClick && (
      <button onClick={onClick}>
        {btnIcon && <i className={btnIcon} />}
        {btnText}
      </button>
    )}
  </div>
)

export default NotificationSection
