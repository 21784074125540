import React from 'react'
import { observer } from 'mobx-react'

import { GET_API_URL } from 'utils/requests.utils'
import { audioPlayer } from 'utils/audio.utils'
import { Files } from '@elo-kit/components/page-builder/blocks/files/Files'
import { patchLink } from 'utils/link.utils'

const FilesPreview = (props) => {
  const getDownloadLink = (id, contentPageId, goodId) =>
    patchLink(
      GET_API_URL(`/cabinet/content_blocks/${id}/download_good`, {
        contentPageId,
        goodId,
      })
    )

  return <Files {...props} getDownloadLink={getDownloadLink} AudioPlayer={audioPlayer} />
}

export default observer(FilesPreview)
