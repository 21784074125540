import React, { Fragment } from 'react'
import classNames from 'classnames'

import { notify } from 'libs/common/notify'

import { PAYMENT_STATES, ORDER_VIEW_TYPES } from 'constants/ordersShared.constants'
import { TRANSFER_STATES } from 'constants/transfersShared.constants'
import { getManagePageLink, withStores } from 'utils/helpers.utils'
import { getThemeProps } from '@elo-kit/utils/block.utils'
import { arrayLast } from 'utils/array.utils'

import { BLOCK_MENU_HIGHLIGHT_IDS } from 'shared/components/page-builder/constants/block.constants'
import { getSearchParams } from 'utils/queryString.utils'

import { PAYMENT_FORMS } from 'constants/paymentSettingShared.constants'
import { patchLink } from 'utils/link.utils'

import PaymentSuccess from './components/PaymentSuccess'
import { PaymentInProgress } from './components/PaymentInProgress'
import PaymentError from './components/PaymentError'
import PaymentCanceled from './components/PaymentCanceled'
import InvoiceButton from './components/InvoiceButton'
import { useThankYouPageStyles } from './thankYouPageStyles'

const ThankYouPagePreview = (props) => {
  const {
    contentPageStore,
    contentPageStore: {
      user: { email },

      seller,
    },
    ordersStore: { item: data, list = [] },
    ordersStore = {},
    sellerStore,
    username,
    previewMode,
    block = {},
  } = props

  const { currencyId, error } = data || {}

  const theme = getThemeProps(block)
  const classes = useThankYouPageStyles({ theme })

  const handleResendEmail = async (token) => {
    if (!previewMode) {
      await ordersStore.resendEmail(username, token)
      notify('success', I18n.t('react.shared.notify.success'), I18n.t('react.shared.notify.sent_email'))
    }
  }

  const redirectToManagePage = (token) => {
    if (!previewMode) {
      window.location.href = patchLink(getManagePageLink(seller, token))
    }
  }

  const searchParams = getSearchParams()

  return (
    <div className={classes.rootContainer}>
      <div className={classes.container}>
        {!!error && <PaymentError error={error} classes={classes} />}

        {!error && (
          <div
            {...(previewMode && {
              'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.header,
              'data-highlighter-selector': '',
            })}
            className={classes.successHeader}
          >
            {theme.headerText || I18n.t('react.shared.thank_you_for_your_order')}
          </div>
        )}

        {list.map((order, index) => {
          const {
            id,
            owner,
            payer,
            deal,
            paymentState,
            activatedAt,
            sendEmail,
            keepAccess,
            token,
            transfers,
            paymentForm,
            invoices,
          } = order

          const isOwner = email === owner?.email || searchParams.from_email || owner?.email === payer.email
          const isPaymentCancelled = paymentState === PAYMENT_STATES.paymentCanceled

          const isGift = owner?.id !== payer.id
          const viewType = isOwner ? ORDER_VIEW_TYPES.owner : ORDER_VIEW_TYPES.payer
          const showTransactionDetails = !isGift || viewType === ORDER_VIEW_TYPES.payer
          const lastTransfer = arrayLast(transfers)

          const hasDifferentBillingAddress = !!deal?.ownerData?.userProfileAttributes

          const commonProps = {
            classes,
            previewMode,
            block,
          }

          const props = {
            currencyId,
            order,
            viewType: isOwner ? ORDER_VIEW_TYPES.owner : ORDER_VIEW_TYPES.payer,
            isGift,
            hasDifferentBillingAddress,
            payerDataFromDeal: deal?.payerData?.userProfileAttributes,
            ownerDataFromDeal: deal?.ownerData?.userProfileAttributes,
            showTransactionDetails,
            lastTransfer,
            ...commonProps,
          }

          const isProductAccessable =
            activatedAt &&
            (paymentState === PAYMENT_STATES.paid || paymentState === PAYMENT_STATES.paying || keepAccess)
          const withDelayedAccess = !activatedAt && paymentState === PAYMENT_STATES.paying

          const isLastOrder = list.length === index + 1

          const oneTransfer =
            transfers.length === 1 ||
            transfers?.filter((transfer) => transfer.state === TRANSFER_STATES.successful).length === 1

          const isPayLater = paymentForm === PAYMENT_FORMS.payLater
          const invoiceLink = invoices?.length && arrayLast(invoices).downloadLink
          const payLaterInvoiceButton = !transfers.length && isPayLater && invoiceLink

          return (
            <Fragment key={id}>
              <div className='thank-you-block'>
                {withDelayedAccess && (
                  <div
                    className={classes.delayedAccess}
                    dangerouslySetInnerHTML={{
                      __html: I18n.t('react.shared.payment.access_delayed'),
                    }}
                  />
                )}
                {isProductAccessable ? (
                  <PaymentSuccess index={index} {...props} contentPageStore={contentPageStore} />
                ) : (
                  <PaymentInProgress
                    {...props}
                    isPaymentCancelled={isPaymentCancelled}
                    contentPageStore={contentPageStore}
                    ordersStore={ordersStore}
                    sellerStore={sellerStore}
                  />
                )}
                {isPaymentCancelled && !keepAccess && (
                  <PaymentCanceled redirectToManagePage={() => redirectToManagePage(token)} classes={classes} />
                )}
                <hr className={classes.lightSeparator} />

                <div className={classes.footer}>
                  <div className={classes.footerButtons}>
                    {oneTransfer && showTransactionDetails && (
                      <InvoiceButton invoiceLink={lastTransfer.invoiceLink} {...commonProps} />
                    )}
                    {!!payLaterInvoiceButton && <InvoiceButton invoiceLink={invoiceLink} {...commonProps} />}
                    <button
                      className={classNames(classes.additionalButton, 'higlight-secondary-button')}
                      onClick={() => redirectToManagePage(token)}
                      {...(previewMode && {
                        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.secondaryButton,
                        'data-highlighter-selector': '.higlight-secondary-button',
                        'data-highlighter-parent': '.thank-you-block',
                      })}
                    >
                      <i className='fas fa-pencil-alt' />
                      <span>{I18n.t('react.shared.manage_page')}</span>
                    </button>
                  </div>

                  {!isPaymentCancelled && sendEmail && (
                    <div className={classNames(classes.footerInfo, 'higlight-details')}>
                      <span
                        {...(previewMode && {
                          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.details,
                          'data-highlighter-selector': '.higlight-details',
                          'data-highlighter-parent': '.thank-you-block',
                        })}
                      >
                        {I18n.t('react.shared.resend_email')}
                      </span>
                      <button
                        className={classNames(classes.link, 'higlight-secondary-button')}
                        {...(previewMode && {
                          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.secondaryButton,
                          'data-highlighter-selector': '.higlight-secondary-button',
                          'data-highlighter-parent': '.thank-you-block',
                        })}
                        onClick={() => handleResendEmail(token)}
                      >
                        {I18n.t('react.shared.button.resend_email')}
                      </button>
                    </div>
                  )}
                </div>
              </div>

              {!isLastOrder && <hr className={classes.bigSeparator} />}
            </Fragment>
          )
        })}
      </div>
    </div>
  )
}

export default withStores(['contentPageStore', 'ordersStore', 'sellerStore'], ThankYouPagePreview)
