import React from 'react'

import { ROOT_URL } from 'constants/general.constants'

import { getLinkByParams } from 'utils/embeddableItems.utils'
import { amountCrossed, withStores } from 'utils/helpers.utils'
import { isWindows } from 'utils/browsers.utils'
import { getMP3dsBrowserMetaData } from 'utils/requests.utils'

import Button from '@elo-kit/components/page-builder/blocks/button'
import { manageOrder } from 'shop/api/orders.api'
import { acceptPostsell } from 'shop/api/funnels.api'
import { patchLink } from 'utils/link.utils'

const ButtonPreview = (props) => {
  const {
    previewMode,
    block,
    historyPush,
    params,
    currenciesStore: { convertToPrice },
    contentPageStore: { fetchProduct, membershipViewType, membershipSellable, seller, view },
    courseViewsStore,
    sellablesStore,
    sellersStore,
    productsStore,
    LinkComponent,
  } = props

  return (
    <Button
      block={block}
      previewMode={previewMode}
      ROOT_URL={patchLink(ROOT_URL)}
      historyPush={historyPush}
      params={params}
      fetchProduct={fetchProduct}
      seller={seller}
      view={view}
      selectLesson={courseViewsStore.selectLesson}
      getLinkByParams={getLinkByParams}
      sellable={sellablesStore.item}
      sellerItem={sellersStore.item}
      manageOrder={manageOrder}
      membershipViewType={membershipViewType}
      membershipSellable={membershipSellable}
      toggleCustomLoading={sellablesStore.toggleCustomLoading}
      fetchSellable={sellablesStore.fetchItem}
      product={productsStore.item}
      acceptPostsell={acceptPostsell}
      LinkComponent={LinkComponent}
      convertToPrice={convertToPrice}
      amountCrossed={amountCrossed}
      isWindows={isWindows}
      getMP3dsBrowserMetaData={getMP3dsBrowserMetaData}
    />
  )
}

const stores = [
  'contentPageStore',
  'currenciesStore',
  'courseViewsStore',
  'sellablesStore',
  'sellersStore',
  'productsStore',
]

export default withStores(stores, ButtonPreview)
