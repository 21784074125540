import React, { useEffect, useState } from 'react'
import { observer } from 'mobx-react'
import classNames from 'classnames'

import { EloButton } from '@elo-ui/components/elo-button'
import { EloInfoMessage } from '@elo-ui/components/elo-info-message'
import { EloDownloadIcon, EloWarningCircleIcon, EloCustomInReview } from '@elo-ui/components/icons/regular'

import { PRODUCT_TYPE_IDS } from 'constants/productsShared.constants'
import { copyTextToClipboard } from 'utils/dom.utils'
import { BLOCK_MENU_HIGHLIGHT_IDS } from 'shared/components/page-builder/constants/block.constants'
import { patchLink } from 'utils/link.utils'

const FILE_STATES = {
  pending: 'pending',
  inProgress: 'in_progress',
  delayed: 'delayed',
  ready: 'ready',
}

const ProductAccess = observer((props) => {
  const {
    order,
    classes,
    product,
    buttonView,
    productIsExpired,
    previewMode,
    block: {
      content: { buttonAnimation },
    },
    sellableItemId,
    downloadGoodsStore,
  } = props
  const [zipFileStatus, setZipFileStatus] = useState(FILE_STATES.pending)

  const checkFileStatus = async () => {
    const resp = await downloadGoodsStore.checkZipFileStatus(order.token)

    if (resp.data?.fileStatus) {
      const isInProgress = resp.data.fileStatus === FILE_STATES.inProgress
      const isDelayed = resp.data.fileStatus === FILE_STATES.delayed

      if (isInProgress || isDelayed) {
        const fetchStatus = setInterval(async () => {
          const resp = await downloadGoodsStore.checkZipFileStatus(order.token)

          const isPending = resp.data?.fileStatus === FILE_STATES.pending
          const isReady = resp.data?.fileStatus === FILE_STATES.ready

          if (isPending || isReady) {
            clearInterval(fetchStatus)
          }

          setZipFileStatus(resp.data?.fileStatus)
        }, 5000)
      }

      setZipFileStatus(resp.data.fileStatus)
    } else {
      setZipFileStatus(FILE_STATES.pending)
    }
  }

  const { fileExits, form, needStamping, licenseKeys } = product

  const { owner, sendEmail } = order

  const isCourse = form === PRODUCT_TYPE_IDS.course
  const isDownload = form === PRODUCT_TYPE_IDS.download
  const isTicket = form === PRODUCT_TYPE_IDS.eventTickets
  const isCertificate = form === PRODUCT_TYPE_IDS.certificate
  const isLicenseKey = form === PRODUCT_TYPE_IDS.licenseKey

  useEffect(() => {
    if (isDownload) {
      checkFileStatus()
    }
  }, [])

  const licenseKeysToCopy = isLicenseKey ? licenseKeys.join(' ') : ''

  const downloadAll = isDownload && !productIsExpired

  const showDownload = !productIsExpired && (isTicket || isCertificate || (isDownload && needStamping && fileExits))

  if (productIsExpired) {
    return (
      <div className={classes.productNoAccess}>
        <h1>
          <i className='fas fa-ban' />
          {I18n.t('react.shop.payment.expired.title')}
        </h1>
        <p>{I18n.t('react.shop.payment.expired.description', { hours: product?.lifetimeHours })}</p>
      </div>
    )
  }

  if (isCourse && sendEmail) {
    return (
      <div
        className={classNames(classes.productAccessEmail, 'higlight-details')}
        {...(previewMode && {
          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.details,
          'data-highlighter-selector': '.higlight-details',
          'data-highlighter-parent': '.thank-you-block',
        })}
      >
        <i className='fas fa-envelope' />
        {I18n.t('react.shared.check_your_email')}
      </div>
    )
  }

  if (showDownload || downloadAll) {
    const handleClick = async () => {
      const isReady = zipFileStatus === FILE_STATES.ready
      if (isDownload && !isReady) {
        setZipFileStatus(FILE_STATES.inProgress)
      }

      const goodId = downloadAll ? 'all' : 'file'

      const resp = await downloadGoodsStore.downloadGoods(order.token, {
        goodId,
        sellableItemId,
      })

      if (resp.data?.redirectPath) {
        window.open(patchLink(resp.data.redirectPath), '_self')
      }

      if (isDownload && !isReady) {
        await checkFileStatus()
      }
    }

    const buttonText = {
      [FILE_STATES.pending]: I18n.t('react.shared.button.request_zip'),
      [FILE_STATES.inProgress]: I18n.t('react.shared.button.processing_zip'),
      [FILE_STATES.delayed]: I18n.t('react.shared.button.processing_zip'),
      [FILE_STATES.ready]: I18n.t('react.shared.button.ready_zip'),
    }[zipFileStatus]

    const buttonIcon = {
      [FILE_STATES.pending]: <EloDownloadIcon />,
      [FILE_STATES.inProgress]: <EloCustomInReview />,
      [FILE_STATES.delayed]: <EloCustomInReview />,
      [FILE_STATES.ready]: <EloDownloadIcon />,
    }[zipFileStatus]

    const isProgress = zipFileStatus === FILE_STATES.inProgress
    const isDelayed = zipFileStatus === FILE_STATES.delayed

    return (
      <>
        {isDelayed && (
          <EloInfoMessage icon={<EloWarningCircleIcon />} status='information' className={classes.mobileEmailNotific}>
            {I18n.t('react.shop.payment.zip_info_message')}
          </EloInfoMessage>
        )}

        <EloButton
          leftIcon={buttonIcon}
          onClick={previewMode ? (e) => e.preventDefault() : handleClick}
          disabled={isProgress || isDelayed}
          className={classNames({
            [classes.transparency]: buttonView && !buttonAnimation,
            [classes[buttonAnimation]]: buttonView && !!buttonAnimation,
            [classes.productDownloadButton]: buttonView,
            [classes.productDownloadLink]: !buttonView,
            [classes.productDownloadButtonDisabled]: isProgress || isDelayed,
          })}
          {...(previewMode && {
            'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.secondaryButton,
            'data-highlighter-selector': '.higlight-secondary-button',
            'data-highlighter-parent': '.thank-you-block',
          })}
        >
          {isTicket || isCertificate ? I18n.t('react.shared.button.download') : buttonText}
        </EloButton>

        {isDelayed && (
          <EloInfoMessage icon={<EloWarningCircleIcon />} status='information' className={classes.desktopEmailNotific}>
            {I18n.t('react.shop.payment.zip_info_message')}
          </EloInfoMessage>
        )}
      </>
    )
  }

  if (isDownload && needStamping && !fileExits && sendEmail) {
    return (
      <div
        className={classes.productNoAccess}
        dangerouslySetInnerHTML={{
          __html: I18n.t('react.shop.payment.success.docs_will_be_sent', { email: owner.email }),
        }}
      />
    )
  }

  if (isLicenseKey && licenseKeysToCopy) {
    return (
      <button
        onClick={() => copyTextToClipboard(licenseKeysToCopy)}
        className={classNames(classes.productDownloadButton, 'higlight-main-button', {
          [classes.transparency]: !buttonAnimation,
          [classes[buttonAnimation]]: !!buttonAnimation,
        })}
        {...(previewMode && {
          'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.mainButton,
          'data-highlighter-selector': '.higlight-main-button',
          'data-highlighter-parent': '.thank-you-block',
        })}
      >
        <i className='far fa-copy' />
        {I18n.t('react.shared.copy_code')}
      </button>
    )
  }

  return null
})

export default ProductAccess
