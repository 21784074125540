import React from 'react'
import classNames from 'classnames'

import { BLOCK_MENU_HIGHLIGHT_IDS } from 'shared/components/page-builder/constants/block.constants'
import { patchLink } from 'utils/link.utils'

const InvoiceButton = ({ invoiceLink, classes, lessMargin, previewMode }) => {
  const handleClick = (e) => {
    if (previewMode) {
      e.preventDefault()
    } else {
      window.open(patchLink(invoiceLink))
    }
  }
  const buttonClasses = classNames(classes.additionalButton, 'higlight-secondary-button', {
    [classes.invoiceButton]: lessMargin,
  })

  return invoiceLink ? (
    <button
      onClick={handleClick}
      className={buttonClasses}
      {...(previewMode && {
        'data-highlighter-item': BLOCK_MENU_HIGHLIGHT_IDS.thankYouPage.secondaryButton,
        'data-highlighter-selector': '.higlight-secondary-button',
        'data-highlighter-parent': '.thank-you-block',
      })}
    >
      <i className='fas fa-receipt' />
      <span>{I18n.t('react.shared.payment.success.download_invoice')}</span>
    </button>
  ) : null
}

export default InvoiceButton
